<style scoped>
.c-stripe {
  display: none;
}
.c-stripe-show {
  display: unset !important;
}
.c-payment-gw-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.c-card {
  height: 16rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.c-card.card {
  border-radius: 1rem !important;
}
.c-card .c-card-pattern {
  position: absolute;
  font-size: 10rem;
  color: rgba(255, 255, 255, 0.05);
  /*line-height: 0.4;*/
  right: 20px;
  bottom: 0px;
}
.c-card .c-card-logo {
  line-height: 0.5;
}
.c-card .c-card-info {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
.btn-replace-pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #4285F4;
  }

  100% {
    box-shadow: 0 0 0 1rem rgba(0, 0, 0, 0);
  }
}
</style>


<script>
import store from "@/state/store";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import {loadStripe} from '@stripe/stripe-js';
import {HalfCircleSpinner} from "epic-spinners";

import {get_acsrf_token} from "@/methods";
import CopyableText from "@/components/CopyableText";
import {PaymentGateway, PaymentMethodType} from "@/enums";
import CopyableInput from "@/components/CopyableInput";

export default {
  props: ['hideDebug'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    CopyableInput,
    HalfCircleSpinner
  },
  methods: {
    async retrievePaymentMethods() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-methods`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.paymentMethods = data.payment_methods;
          this.canDeletePaymentMethod = data.can_delete;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async retrievePaymentGateways() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateways`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.paymentGateways = data.payment_gateways;
          this.paymentInfo.country = data.meta.country;
          this.paymentInfo.region = data.meta.region;
          this.isFromMemberState = data.meta.member_state;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async removePaymentMethod(paymentMethodId) {
      this.removingPaymentMethod = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          payment_method_id: paymentMethodId
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-method`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.removingPaymentMethod = false;
          this.ready = false;
        } else {
          this.removingPaymentMethod = false;
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.removingPaymentMethod = false;
        this.$swal({
          icon: 'error',
          text: this.$t('billing.payment.remove.error')
        });
      }
    },
    async chargeAccountWallet() {
      if(this.chargingWallet) return;
      this.chargingWallet = true;
      this.$refs.chargeAccountWallet.show();
    },
    async breakChargeAccountWallet(event) {
      if(this.walletChargeProcessing) {
        event.preventDefault();
      } else this.chargingWallet = false;
    },
    async processWalletCharge() {
      if(!this.chargingWalletBalance) return;
      this.walletChargeProcessing = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          amount: this.chargingWalletBalance
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/wallet/initiate-charge`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          // nothing
        } else {
          this.walletChargeProcessing = false;
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.walletChargeProcessing = false;
        this.$swal({
          icon: 'error',
          text: `An error occurred while initiating the charge`
        });
      }

    },
    async resetAddPaymentMethodProcess() {
      this.paymentGateway = null;
    },
    async addPaymentMethod() {
      await this.resetAddPaymentMethodProcess();
      this.addingPaymentMethod = true;
      this.$refs.addPaymentMethod.show();
    },
    async breakAddPaymentMethod(event) {
      if(this.paymentGatewayProcessing) {
        event.preventDefault();
      } else this.addingPaymentMethod = false;
    },
    async selectPaymentGateway(paymentGateway) {
      if(this.paymentGatewayProcessing) return;
      if(this.paymentGateway === paymentGateway) return;

      this.$refs.stripeCardPayment.classList.remove('c-stripe-show');
      this.$refs.stripeSepaPayment.classList.remove('c-stripe-show');
      this.$refs.stripeSofortPayment.classList.remove('c-stripe-show');

      switch(paymentGateway) {
        case 'stripe-cc': {
          await this.bootstrapStripeCardElement();
          this.$refs.stripeCardPayment.classList.add('c-stripe-show');
          break;
        }

        case 'stripe-sepa': {
          await this.bootstrapStripeSEPAElement();
          this.$refs.stripeSepaPayment.classList.add('c-stripe-show');
          break;
        }

        case 'stripe-sofort': {
          this.$refs.stripeSofortPayment.classList.add('c-stripe-show');
          break;
        }

        default: {
          break;
        }
      }
      this.paymentGateway = paymentGateway;
    },
    async bootstrapStripeCardElement() {
      if(this.stripeElements.getElement('card')) return this.stripeElements.getElement('card').mount(this.$refs.stripeCard);
      this.$nextTick(() => {
        let card = this.stripeElements.create('card', {style: {
            base: {
              color: 'white',
              lineHeight: '18px',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#bfc8e2'
              }
            },
            invalid: {
              color: '#f46a6a',
              iconColor: '#f46a6a'
            }
          }});
        card.mount(this.$refs.stripeCard);
        card.addEventListener('change', function(event) {
          var displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
      });
    },
    async bootstrapStripeSEPAElement() {
      if(this.stripeElements.getElement('iban')) return this.stripeElements.getElement('iban').mount(this.$refs.stripeIban);
      this.$nextTick(() => {
        let iban = this.stripeElements.create('iban', {
          supportedCountries: ['SEPA'],
          placeholderCountry: this.paymentInfo.country,
          style: {
            base: {
              color: 'white',
              lineHeight: '18px',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#bfc8e2'
              }
            },
            invalid: {
              color: '#f46a6a',
              iconColor: '#f46a6a'
            }
          }});
        iban.mount(this.$refs.stripeIban);
        iban.addEventListener('change', function(event) {
          var displayError = document.getElementById('iban-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
      });
    },
    async confirmPaymentGateway() {
      if(!['paypal', 'cftools-wallet'].includes(this.paymentGateway) && (!this.paymentInfo.firstName || !this.paymentInfo.lastName || !this.paymentInfo.country || !this.paymentInfo.region || !this.paymentGateway)) {
        this.paymentInfoError = true;
        return;
      }

      if(this.paymentGateway === 'cftools-wallet') {
        if(!this.walletInfoAcknowledged) {
          this.paymentInfoError = true;
          return;
        }
      }

      this.paymentInfoError = false;
      this.paymentGatewayProcessing = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gateway: this.paymentGateway
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/initiate`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(!response.ok) {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.paymentGatewayProcessing = false;
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.walletInfoAcknowledged = false;
    },
    async processPaymentGatewayRemoval() {
      await this.retrievePaymentMethods();
      this.ready = true;
    },
    async processPaymentGatewayFinalization(data) {
      this.paymentGatewayProcessing = false;
      this.$refs.addPaymentMethod.hide();
      if(data.error) {
        this.finalizingPaymentMethod = false;
        this.$swal({
          icon: 'error',
          text: data.message || this.$t('error.server.generic.message')
        });
      } else {
        this.$swal({
          icon: 'success',
          text: this.$t('billing.payment.add.success')
        });
        await this.retrievePaymentMethods();
        if (!this.ready) this.ready = true;
        this.finalizingPaymentMethod = false;
        let query = Object.assign({}, this.$route.query);
        delete query.gateway;
        delete query.action;
        await this.$router.replace({query});
      }
    },
    async testPayPal() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/testing/paypal`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async testStripe() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/testing/stripe`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async postprocessPaymentMethod(gateway, token) {
      this.finalizingPaymentMethod = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gateway: this.paymentGateway || gateway,
          token: token
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/finalize`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(!response.ok) {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.paymentGatewayProcessing = false;
        this.$swal({
          icon: 'error',
          text: this.$t('billing.payment.add.error')
        });
        return false;
      }
      return true;
    },
    async handleStripeGatewayResponse(data) {
      if(this.paymentGateway === 'stripe-cc') {
        let result = await this.stripeObject.confirmCardSetup(
            data.client_secret,
            {
              payment_method: {
                card: this.stripeElements.getElement('card'),
                billing_details: {
                  name: `${this.paymentInfo.firstName} ${this.paymentInfo.lastName}`,
                  email: data.email
                },
              },
            }
        );
        if(result.error) {
          this.$swal({
            icon: 'error',
            title: this.$t('billing.payment.add.error'),
            text: result.error.message
          });
          this.paymentGatewayProcessing = false;
        } else {
          await this.postprocessPaymentMethod('stripe');
        }
      } else if(this.paymentGateway === 'stripe-sepa') {
        let result = await this.stripeObject.confirmSepaDebitSetup(
            data.client_secret,
            {
              payment_method: {
                sepa_debit: this.stripeElements.getElement('iban'),
                billing_details: {
                  name: `${this.paymentInfo.firstName} ${this.paymentInfo.lastName}`,
                  email: data.email
                },
              },
            }
        );
        if(result.error) {
          this.$swal({
            icon: 'error',
            title: this.$t('billing.payment.add.error'),
            text: result.error.message
          });
          this.paymentGatewayProcessing = false;
        } else {
          await this.postprocessPaymentMethod('stripe');
        }
      } else if(this.paymentGateway === 'stripe-sofort') {
        let result = await this.stripeObject.confirmSofortSetup(
            data.client_secret,
            {
              payment_method: {
                sofort: {
                  country: this.paymentInfo.country
                },
                billing_details: {
                  name: `${this.paymentInfo.firstName} ${this.paymentInfo.lastName}`,
                  email: data.email
                },
              },
              return_url: `${window.location.origin}/billing/payment-methods?gateway=stripe-sofort&action=finalize&token=${data.id}`
            }
        );
        if(result.error) {
          this.$swal({
            icon: 'error',
            title: this.$t('billing.payment.add.error'),
            text: result.error.message
          });
          this.paymentGatewayProcessing = false;
        } else {
          await this.postprocessPaymentMethod('stripe');
        }
      }
    },
    async processPaymentGatewayRequirements(data) {
      switch(data.gateway) {
        case PaymentGateway.STRIPE: {
          let result = await this.stripeObject.confirmCardPayment(
              data.client_secret,
              {
                payment_method: data.payment_method_id
              }
          );
          if(result.error) {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.payment.confirm.error')
            });
          } else if(result.paymentIntent.status === 'succeeded') {
            console.log('payment went through');
          }
          break;
        }
      }
    },
    async handlePayPalGatewayResponse(data) {
      location.replace(data.redirect_url);
    },
    async processPaymentGatewayResponse(data) {
      switch(this.paymentGateway) {
        case 'stripe-cc': {
          await this.handleStripeGatewayResponse(data);
          break;
        }
        case 'stripe-sepa': {
          await this.handleStripeGatewayResponse(data);
          break;
        }
        case 'stripe-sofort': {
          await this.handleStripeGatewayResponse(data);
          break;
        }
        case 'paypal': {
          await this.handlePayPalGatewayResponse(data);
          break;
        }
        case 'cftools-wallet': {
          location.replace(data.redirect_url);
          break;
        }
        default: {
          if(data.redirect_url) location.replace(data.redirect_url);
          break;
        }
      }
    },
    async requestCryptoPaymentStatus() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          payment_id: this.cryptoPaymentInfo.id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/wallet/charge-status`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async processWalletCryptoUpdate(data) {
      if(data.mode === 'redirect') {
        location.replace(data.redirect_url);
      } else {
        this.cryptoPaymentInfo.id = data.payment.id;
        this.cryptoPaymentInfo.status = 'new';
        this.cryptoPaymentInfo.expires = 600;
        this.cryptoPaymentInfo.timer = setInterval(() => {
          this.cryptoPaymentInfo.expires = this.cryptoPaymentInfo.expires -1;
          if(this.cryptoPaymentInfo.expires <= 0) {
            if(this.cryptoPaymentInfo.status !== 'paid') {
              this.cryptoPaymentInfo.status = 'expired';
              this.walletChargeProcessing = false;
              clearInterval(this.cryptoPaymentInfo.timer);
            }
          }
          if(this.cryptoPaymentInfo.expires % 10 === 0) {
            this.requestCryptoPaymentStatus();
          }
        }, 1000);
        this.cryptoPaymentInfo.address = data.payment.bitcoin.address;
        this.cryptoPaymentInfo.amount = data.payment.bitcoin.amount;
				this.cryptoPaymentInfo.redirect_url = data.redirect_url;
      }
    },
    async processWalletCryptoStatusUpdate(data) {
      this.cryptoPaymentInfo.status = data.status;
      if(this.cryptoPaymentInfo.status === 'complete') {
        this.cryptoPaymentInfo.id = null;
        this.walletChargeProcessing = false;
        this.$refs.chargeAccountWallet.hide();
        clearInterval(this.cryptoPaymentInfo.timer);
        this.$swal({
          icon: 'success',
          text: `Payment successful`
        });
        await this.retrievePaymentGateways();
        this.chargingWallet = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:gateway:setup', this.processPaymentGatewayResponse);
      this.$socket.client.on('billing:gateway:confirm', this.processPaymentGatewayRequirements);
      this.$socket.client.on('billing:gateway:finalized', this.processPaymentGatewayFinalization);
      this.$socket.client.on('billing:gateway:removed', this.processPaymentGatewayRemoval);
      this.$socket.client.on('billing:wallet:crypto:initiate', this.processWalletCryptoUpdate);
      this.$socket.client.on('billing:wallet:crypto:status', this.processWalletCryptoStatusUpdate);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.retrievePaymentGateways();
    this.stripeObject = await loadStripe(this.paymentGateways.stripe.pk);
    this.stripeElements = this.stripeObject.elements();

    if(this.$route.query.gateway) {
      if(this.$route.query.action === 'finalize') {
        if(this.$route.query.gateway === 'paypal') {
          this.ready = false;
          this.finalizingPaymentMethod = true;
          let status = await this.postprocessPaymentMethod('paypal', this.$route.query.token);
          if (status) return;
        } else if(this.$route.query.gateway === 'stripe-sofort') {
          if(this.$route.query.redirect_status === 'failed') {
            this.finalizingPaymentMethod = false;
            this.$swal({
              icon: 'error',
              title: this.$t('billing.payment.add.error')
            });
          } else {
            this.ready = false;
            this.finalizingPaymentMethod = true;
            let status = await this.postprocessPaymentMethod('stripe-sofort', this.$route.query.token);
            if (status) return;
          }
        }
      }
    }

    await this.retrievePaymentMethods();
    if(!this.finalizingPaymentMethod) {
      this.ready = true;
    }
  },
  destroyed() {
    if(this.cryptoPaymentInfo.timer) clearInterval(this.cryptoPaymentInfo.timer);
    try {
      this.$socket.client.off('billing:gateway:setup', this.processPaymentGatewayResponse);
      this.$socket.client.off('billing:gateway:confirm', this.processPaymentGatewayRequirements);
      this.$socket.client.off('billing:gateway:finalized', this.processPaymentGatewayFinalization);
      this.$socket.client.off('billing:gateway:removed', this.processPaymentGatewayRemoval);
      this.$socket.client.off('billing:wallet:crypto:initiate', this.processWalletCryptoUpdate);
      this.$socket.client.off('billing:wallet:crypto:status', this.processWalletCryptoStatusUpdate);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      PaymentGateway:PaymentGateway,
      PaymentMethodType:PaymentMethodType,
      ready: false,
      error: false,
      isFromMemberState: false,
      paymentMethods: [],
      paymentGateways: {},
      canDeletePaymentMethod: false,
      addingPaymentMethod: false,
      removingPaymentMethod: false,
      chargingWallet: false,
      walletInfoAcknowledged: false,
      walletChargeProcessing: false,
      chargingWalletBalance: 0.00,
      finalizingPaymentMethod: false,
      paymentGateway: null,
      stripeObject: null,
      paymentInfo: {
        firstName: null,
        lastName: null,
        country: null,
        region: null
      },
      cryptoPaymentInfo: {
        id: null,
        status: 'new',
        expires: -1,
        address: null,
        amount: null,
	      redirect_url: null
      },
      paymentInfoError: false,
      paymentGatewayProcessing: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <template v-if="paymentMethods.length === 0">
        <div class="row">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-empty-set info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3>{{$t('billing.payment.empty.title')}}</h3>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <button class="btn btn-primary btn-lg text-uppercase" :disabled="addingPaymentMethod" v-on:click="addPaymentMethod()">
                    <h5 class="mb-0">
                      <half-circle-spinner
                          v-if="addingPaymentMethod"
                          :animation-duration="900"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      {{$t('billing.payment.empty.button')}}
                    </h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="$applicationEnvironment === 'development' && !hideDebug">
          <div class="row justify-content-center mt-4 mb-4">
            <div class="col-lg-4 col-sm-12">
              <h3 class="text-danger">DEBUG OPTIONS</h3>
              <button class="btn btn-block btn-danger" v-on:click="testPayPal()">
                <i class="fab fa-paypal"/>
                Charge All PayPal Accounts
              </button>
              <button class="btn btn-block btn-danger" v-on:click="testStripe()">
                <i class="fab fa-stripe"/>
                Charge All Stripe Accounts
              </button>
            </div>
          </div>
        </template>
        <div class="row justify-content-center">
          <div class="col-lg-5 col-sm-12 c-sm-spacer" v-for="(paymentMethod) in paymentMethods" :key="paymentMethod.id">
            <template v-if="paymentMethod.gateway === PaymentGateway.PAYPAL">
              <div class="card text-white c-card mb-0" :class="{'bg-primary': paymentMethod.valid, 'bg-danger': !paymentMethod.valid}">
                <div class="card-body">
                  <div>
                    <i class="fab fa-paypal c-card-pattern d-inline-block"></i>
                    <div class="float-end d-inline-block">
                      <i class="fab fa-paypal c-card-logo display-4"></i>
                    </div>
                    <div class="float-right top-right d-inline-block">
                      <button class="btn btn-warning text-black" :disabled="removingPaymentMethod" :class="{'disabled': removingPaymentMethod}" v-on:click="removePaymentMethod(paymentMethod.id)" v-if="canDeletePaymentMethod">
                        <half-circle-spinner
                            v-if="removingPaymentMethod"
                            :animation-duration="900"
                            :size="18"
                            class="align-middle d-inline-block"
                        />
                        <template v-if="!removingPaymentMethod">
                          {{$t('billing.payment.remove.button')}}
                        </template>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col">
                      <p>
                        <span class="m-1 h3 c-card-info">{{paymentMethod.details.email}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="mt-5">
                    <h5 class="text-white float-end mb-0">
                      {{paymentMethod.details.name}}
                      <small v-if="paymentMethod.primary">
                        <i class="fad fa-badge-check"/>
                        <span class="text-white">
                          {{$t('billing.payment.list.primary')}}
                        </span>
                      </small>
                    </h5>
                    <h5 class="text-muted mb-0">
                      {{paymentMethod.details.country}}
                      <span class="badge badge-light" v-if="!paymentMethod.valid">
                          <i class="fas fa-exclamation-triangle text-danger"/>
                          {{$t('billing.payment.list.invalid')}}
                        </span>
                    </h5>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="paymentMethod.gateway === PaymentGateway.STRIPE">
              <div class="card text-white c-card mb-0" :class="{'bg-primary': paymentMethod.valid, 'bg-danger': !paymentMethod.valid}">
                <div class="card-body">
                  <div>
                    <i class="fab fa-stripe c-card-pattern d-inline-block"></i>
                    <div class="float-end d-inline-block">
                      <template v-if="paymentMethod.type === PaymentMethodType.SEPA">
                        <i class="fas fa-university c-card-logo display-4"/>
                      </template>
                      <template v-else-if="paymentMethod.type === PaymentMethodType.CARD">
                        <template v-if="paymentMethod.details.brand === 'visa'">
                          <i class="fab fa-cc-visa c-card-logo display-4"/>
                        </template>
                        <template v-else>
                          <i class="fad fa-credit-card c-card-logo display-4"/>
                        </template>
                      </template>
                    </div>
                    <div class="float-right top-right d-inline-block">
                      <button class="btn btn-warning text-black" :disabled="removingPaymentMethod" :class="{'disabled': removingPaymentMethod}" v-on:click="removePaymentMethod(paymentMethod.id)" v-if="canDeletePaymentMethod">
                        <half-circle-spinner
                            v-if="removingPaymentMethod"
                            :animation-duration="900"
                            :size="18"
                            class="align-middle d-inline-block"
                        />
                        <template v-if="!removingPaymentMethod">
                          {{$t('billing.payment.remove.button')}}
                        </template>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-4">
                      <p class="mt-1">
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="mt-1">
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                      </p>
                    </div>
                    <div class="col-4">
                      <p>
                        <span class="m-1 h3">{{paymentMethod.details.last4}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="mt-5">
                    <template v-if="paymentMethod.type === PaymentMethodType.SEPA">
                      <h5 class="text-white float-end mb-0">
                        SEPA
                          <small v-if="paymentMethod.primary">
                            <i class="fad fa-badge-check"/>
                            <span class="text-white">
                            {{$t('billing.payment.list.primary')}}
                          </span>
                        </small>
                      </h5>
                      <h5 class="text-muted mb-0">
                        BIC {{paymentMethod.details.bank_code}}
                        <span class="badge badge-light" v-if="!paymentMethod.valid">
                          <i class="fas fa-exclamation-triangle text-danger"/>
                          {{$t('billing.payment.list.invalid')}}
                        </span>
                      </h5>
                    </template>
                    <template v-else-if="paymentMethod.type === PaymentMethodType.CARD">
                      <h5 class="text-white float-end mb-0">
                        {{capitalizeFirstLetter(paymentMethod.details.brand)}}
                        <small class="text-muted">{{paymentMethod.details.country}}</small>
                        <small class="ml-1" v-if="paymentMethod.primary">
                          <i class="fad fa-badge-check"/>
                            <span class="text-white">
                            {{$t('billing.payment.list.primary')}}
                          </span>
                        </small>
                      </h5>
                      <h5 class="text-muted mb-0">
                        {{paymentMethod.details.exp_month}}/{{paymentMethod.details.exp_year}}
                        <span class="badge badge-light" v-if="!paymentMethod.valid">
                          <i class="fas fa-exclamation-triangle text-danger"/>
                          {{$t('billing.payment.list.invalid')}}
                        </span>
                      </h5>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="paymentMethod.gateway === PaymentGateway.CFTOOLS">
              <div class="card text-white c-card mb-0" :class="{'bg-primary': paymentMethod.valid, 'bg-danger': !paymentMethod.valid}">
                <div class="card-body">
                  <div>
                    <template v-if="paymentMethod.type === PaymentMethodType.INVOICING">
                      <i class="far fa-money-check c-card-pattern d-inline-block"></i>
                      <div class="float-end d-inline-block">
                          <i class="fas fa-file-invoice-dollar c-card-logo display-4"/>
                      </div>
                    </template>
                    <template v-else-if="paymentMethod.type === PaymentMethodType.WALLET">
                      <i class="far fa-wallet c-card-pattern d-inline-block"></i>
                      <div class="float-end d-inline-block">
                        <i class="fad fa-wallet c-card-logo display-4"/>
                      </div>
                    </template>
                    <div class="float-right top-right d-inline-block">
                      <button class="btn btn-warning text-black" :disabled="removingPaymentMethod" :class="{'disabled': removingPaymentMethod}" v-on:click="removePaymentMethod(paymentMethod.id)" v-if="canDeletePaymentMethod">
                        <half-circle-spinner
                            v-if="removingPaymentMethod"
                            :animation-duration="900"
                            :size="18"
                            class="align-middle d-inline-block"
                        />
                        <template v-if="!removingPaymentMethod">
                          {{$t('billing.payment.remove.button')}}
                        </template>
                      </button>
                    </div>
                    <div class="float-right top-right mr-2 d-inline-block" v-if="paymentMethod.type === PaymentMethodType.WALLET">
                      <button class="btn btn-light" :disabled="chargingWallet" :class="{'disabled': chargingWallet}" v-on:click="chargeAccountWallet()">
                        <half-circle-spinner
                            v-if="chargingWallet"
                            :animation-duration="900"
                            :size="18"
                            class="align-middle d-inline-block"
                        />
                        <template v-if="!chargingWallet">
                          <i class="fad fa-coins"/> Charge Wallet
                        </template>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <template v-if="paymentMethod.type === PaymentMethodType.INVOICING">
                    <div class="col-4">
                      <p class="mt-1">
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="mt-1">
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                      </p>
                    </div>
                    <div class="col-4">
                      <p>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                        <i class="fas fa-star-of-life m-1"></i>
                      </p>
                    </div>
                    </template>
                    <template v-else-if="paymentMethod.type === PaymentMethodType.WALLET">
                      <div class="col-4">
                        <h1 class="mt-1 mb-0 p-0">
                          {{ paymentMethod.details.content }}
                          <template v-if="paymentMethod.details.currency_code.settlement === 'EUR'">
                            €
                          </template>
                        </h1>
                      </div>
                    </template>
                  </div>
                  <div class="mt-5">
                    <template v-if="paymentMethod.type === PaymentMethodType.INVOICING">
                      <h5 class="text-white float-end mb-0">
                        Invoicing
                        <small v-if="paymentMethod.primary">
                          <i class="fad fa-badge-check"/>
                          <span class="text-white">
                            {{$t('billing.payment.list.primary')}}
                          </span>
                        </small>
                      </h5>
                      <h5 class="text-muted mb-0">
                        Manual Bank Transfer {{paymentMethod.details.bank_code}}
                        <span class="badge badge-light" v-if="!paymentMethod.valid">
                          <i class="fas fa-exclamation-triangle text-danger"/>
                          {{$t('billing.payment.list.invalid')}}
                        </span>
                      </h5>
                    </template>
                    <template v-if="paymentMethod.type === PaymentMethodType.WALLET">
                      <h5 class="text-white float-end mb-0">
                        CFTools Account Wallet
                        <small v-if="paymentMethod.primary">
                          <i class="fad fa-badge-check"/>
                          <span class="text-white">
                            {{$t('billing.payment.list.primary')}}
                          </span>
                        </small>
                      </h5>
                      <h5 class="text-muted mb-0">
                        <template v-if="paymentMethod.details.currency_code.settlement === 'EUR'">
                           <span class="text-white">
                             EUR
                           </span>
                        </template>
                        <template v-else>
                          !ERROR! CONTACT SUPPORT
                        </template>
                        <span class="text-small font-size-13">
                          Settlement Currency
                        </span>
                        <span class="badge badge-light" v-if="!paymentMethod.valid">
                          <i class="fas fa-exclamation-triangle text-danger"/>
                          {{$t('billing.payment.list.invalid')}}
                        </span>
                      </h5>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>

        </div>
        <template v-if="paymentMethods.length === 1">
          <div class="row justify-content-center mt-4">
            <div class="col-lg-5 col-sm-12">
              <div class="card bg-default text-white c-card mb-0" style="height: 5rem !important;">
                <div class="card-body">
                  <div class="row h-100 justify-content-center align-items-center">
                    <template v-if="paymentMethods[0].valid">
                      <button class="btn btn-info btn-lg text-uppercase" :disabled="addingPaymentMethod" v-on:click="addPaymentMethod()">
                        <h5 class="mb-0">
                          <half-circle-spinner
                              v-if="addingPaymentMethod"
                              :animation-duration="900"
                              :size="18"
                              class="align-middle d-inline-block"
                          />
                          {{$t('billing.payment.list.backup')}}
                        </h5>
                      </button>
                    </template>
                    <template v-else>
                      <button class="btn btn-primary btn-replace-pulse btn-lg text-uppercase" :disabled="addingPaymentMethod" v-on:click="addPaymentMethod()">
                        <h5 class="mb-0">
                          <half-circle-spinner
                              v-if="addingPaymentMethod"
                              :animation-duration="900"
                              :size="18"
                              class="align-middle d-inline-block"
                          />
                          {{$t('billing.payment.list.replacement')}}
                        </h5>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

      </template>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="finalizingPaymentMethod">
        <div class="row justify-content-center">
          <div class="col text-center">
            <h4>{{ $t('billing.payment.add.finalizing') }}</h4>
          </div>
        </div>
      </template>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>


    <!-- Modal Payment Method -->
    <b-modal
        id="chargeAccountWallet"
        ref="chargeAccountWallet"
        size="md"
        hide-header
        hide-footer
        content-class="hard-border"
        @hide="breakChargeAccountWallet($event)"
    >
      <div>
        <div>
          <h3 class="d-inline-block">
            Charge Wallet
          </h3>
          <button class="btn btn-sm btn-outline-danger float-right d-inline-block" @click="$bvModal.hide('chargeAccountWallet')" v-if="!walletChargeProcessing">
            {{$t('billing.payment.add.close')}}
          </button>
        </div>
        <hr/>
        <div class="row">
          <div class="col">
            <h5>Amount to charge</h5>
	          <div class="row">
		          <div class="col">
			          <div class="alert alert-info">
				          <div class="alert-heading h4 text-info m-0 p-0">
										Crypto payment service disruption
				          </div>
				          <hr/>
				          <p>
					          Due to the high variance of BTC, wallets can only be charged with a minimum charge of 31,- EUR at this time.<br/>
				          </p>
			          </div>
		          </div>
	          </div>
            <b-form-group>
              <b-form-radio-group
                  id="radio-group-2"
                  v-model="chargingWalletBalance"
                  name="radio-sub-component"
              >
	              <!--
                <b-form-radio value="10.00">
                  10,00 €
                </b-form-radio>
                <b-form-radio value="25.00">
                  25,00 €
                </b-form-radio>
                -->
	              <b-form-radio value="31.00">
		              31,00 €
	              </b-form-radio>
	              <b-form-radio value="40.00">
		              40,00 €
	              </b-form-radio>
                <b-form-radio value="50.00">
                  50,00 €
                </b-form-radio>
	              <b-form-radio value="75.00">
		              75,00 €
	              </b-form-radio>
                <b-form-radio value="100.00">
                  100,00 €
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <template v-if="cryptoPaymentInfo.id">
              <hr>
              <template v-if="cryptoPaymentInfo.status === 'new'">
                <h4>
                  Transaction Status:
                  <span class="text-warning">
                  Pending
                </span>
                </h4>
                <b class="text-white">Target Bitcoin Address</b>
                <CopyableInput :text="cryptoPaymentInfo.address"  class="text-code" />
                <b class="text-white">Bitcoin Amount</b>
                <CopyableInput :text="cryptoPaymentInfo.amount" class="text-code" />
                <hr/>
                <div class="text-center mb-2">
                  Time left: {{ cryptoPaymentInfo.expires }} seconds
                </div>
	              <small class="text-center mt-1">
		              <a :href="this.cryptoPaymentInfo.redirect_url">Checkout via Coinify</a>
	              </small>
              </template>
              <template v-else-if="cryptoPaymentInfo.status === 'expired'">
                <h4 class="text-center">
                  <span class="text-warning">
                    Transaction expired, please initiate a new one
                  </span>
                </h4>
              </template>
              <template v-else-if="cryptoPaymentInfo.status === 'paid'">
                <h4 class="text-center">
                  <span class="text-primary">
                    Transaction acknowledged, waiting for confirmation
                  </span>
                </h4>
              </template>
              <template v-else-if="cryptoPaymentInfo.status === 'complete'">
                <h4 class="text-center">
                  <span class="text-success">
                    Transaction completed
                  </span>
                </h4>
              </template>
            </template>
            <div class="text-center">
              <button class="btn btn-md btn-outline-success" @click="processWalletCharge()" :disabled="walletChargeProcessing" :class="{'disabled': walletChargeProcessing}">
                <half-circle-spinner
                    v-if="walletChargeProcessing"
                    :animation-duration="900"
                    :size="18"
                    class="align-middle d-inline-block"
                />
                Checkout with Cryptocurrency
              </button>
	            <!--
	            <a :href="cryptoPaymentInfo.redirect_url" class="btn btn-md mt-2 btn-outline-info" v-if="cryptoPaymentInfo && cryptoPaymentInfo.redirect_url">
		            Checkout with Cryptocurrency (All alt-coins)
	            </a>
	            -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal Payment Method -->
    <b-modal
        id="addPaymentMethod"
        ref="addPaymentMethod"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border"
        @hide="breakAddPaymentMethod($event)"
        v-if="stripeObject"
    >
      <div>
        <div>
          <h3 class="d-inline-block">
            {{$t('billing.payment.add.title')}}
          </h3>
          <button class="btn btn-sm btn-outline-danger float-right d-inline-block" @click="$bvModal.hide('addPaymentMethod')" v-if="!paymentGatewayProcessing">
            {{$t('billing.payment.add.close')}}
          </button>
        </div>
	      <template v-if="paymentGateways.paypal.express && (paymentGateways.paypal.express_unavailable === true)">
		      <hr/>
		      <div class="alert alert-warning border-warning">
			      <h4 class="alert-heading text-black">
				      PayPal Service Issue
			      </h4>
			      <hr/>
			      <p class="mb-0 text-black">
				      Due to a PayPal service disruption we are currently unable to accept PayPal payments. We are working with PayPal to resolve the issue.<br/>
				      <br/>
				      We are sorry for the inconvenience.
			      </p>
		      </div>
	      </template>
        <hr>
        <div class="row justify-content-center">
          <!-- CARD -->
          <div class="col-lg col-sm-12 c-payment-gw-btn" v-on:click="selectPaymentGateway('stripe-cc')" v-if="paymentGateways.stripe.card" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
            <label class="card-radio-label mb-3">
              <input type="radio" name="pay-method" class="card-radio-input" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
              <div class="card-radio">
                <i class="fad fa-credit-card font-size-24 text-primary mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  {{ $t('billing.payment.add.gateway.stripe.cc_title') }}
                  <div class="small text-muted">
                    {{ $t('billing.payment.add.gateway.stripe.disclaimer') }}
                  </div>
                </h5>
              </div>
            </label>
          </div>
          <!-- CARD END -->

          <!-- PAYPAL VANILLA -->
	        <template v-if="paymentGateways.paypal.express && (paymentGateways.paypal.express_unavailable === null || paymentGateways.paypal.express_unavailable === false)">
	          <div class="col-lg col-sm-12 c-payment-gw-btn" v-on:click="selectPaymentGateway('paypal')" v-if="paymentGateways.paypal.express" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
	            <label class="card-radio-label mb-3">
	              <input type="radio" name="pay-method" class="card-radio-input" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
	              <div class="card-radio">
	                <i class="fab fa-paypal font-size-24 text-primary mt-2 me-2"></i>
	                <h5 class="d-inline-block p-0 align-middle ml-2">
	                  {{ $t('billing.payment.add.gateway.paypal.title') }}
	                  <div class="small text-muted">
	                    {{ $t('billing.payment.add.gateway.paypal.disclaimer') }}
	                  </div>
	                </h5>
	              </div>
	            </label>
	          </div>
	        </template>
	        <template v-else-if="paymentGateways.paypal.express && (paymentGateways.paypal.express_unavailable === true)">
		        <div class="col-lg col-sm-12 c-payment-gw-btn" disabled="true" :class="{'disabled': true}">
			        <label class="card-radio-label mb-3 border-1">
				        <div class="card-radio border-warning">
					        <i class="fab fa-paypal font-size-24 text-primary mt-2 me-2"></i>
					        <h5 class="d-inline-block p-0 align-middle ml-2">
						        {{ $t('billing.payment.add.gateway.paypal.title') }}
						        <div class="small text-warning h6">
							        Temporarily Unavailable
						        </div>
					        </h5>
				        </div>
			        </label>
		        </div>
	        </template>
          <!-- PAYPAL VANILLA END -->

          <!-- SEPA -->
          <!--
          <div class="col-lg-3 col-sm-12 c-payment-gw-btn" v-on:click="selectPaymentGateway('stripe-sepa')" v-if="paymentGateways.stripe.sepa" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
            <label class="card-radio-label mb-3">
              <input type="radio" name="pay-method" class="card-radio-input" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
              <div class="card-radio">
                <i class="fad fa-university font-size-24 text-primary mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  {{ $t('billing.payment.add.gateway.stripe.sepa_title') }}
                  <div class="small text-muted">
                    {{ $t('billing.payment.add.gateway.stripe.disclaimer') }}
                  </div>
                </h5>
              </div>
            </label>
          </div>
          <div class="col-lg-3 col-sm-12 c-payment-gw-btn disabled" disabled v-else-if="isFromMemberState">
            <label class="card-radio-label mb-3">
              <div class="card-radio">
                <i class="fad fa-university font-size-24 text-warning mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  {{ $t('billing.payment.add.gateway.stripe.sepa_title') }}
                  <div class="small text-warning mb-n3">
                    {{ $t('billing.payment.add.gateway.stripe.sepa_unavailable1') }}
                    <br>
                    <a href="/support">
                      {{ $t('billing.payment.add.gateway.stripe.sepa_unavailable2') }} <small><i class="fas fa-external-link"/></small>
                    </a>
                  </div>
                </h5>
              </div>
            </label>
          </div>
          -->
          <!-- SEPA END -->

          <!-- SOFORT -->
          <div class="col-lg col-sm-12 c-payment-gw-btn" v-on:click="selectPaymentGateway('stripe-sofort')" v-if="paymentGateways.stripe.sofort.allowed" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
            <label class="card-radio-label mb-3">
              <input type="radio" name="pay-method" class="card-radio-input" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
              <div class="card-radio">
                <i class="fad fa-university font-size-24 text-primary mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  {{ $t('billing.payment.add.gateway.stripe.sofort_title') }}
                  <div class="small text-muted">
                    {{ $t('billing.payment.add.gateway.stripe.disclaimer') }}
                  </div>
                </h5>
              </div>
            </label>
          </div>
          <!--
          <div class="col-lg-3 col-sm-12 c-payment-gw-btn disabled" disabled v-else-if="paymentGateways.stripe.sofort.available">
            <label class="card-radio-label mb-3">
              <div class="card-radio">
                <i class="fad fa-university font-size-24 text-warning mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  {{ $t('billing.payment.add.gateway.stripe.sofort_title') }}
                  <div class="small text-warning mb-n3">
                    {{ $t('billing.payment.add.gateway.stripe.sepa_unavailable1') }}
                    <br>
                    <a href="/support">
                      {{ $t('billing.payment.add.gateway.stripe.sepa_unavailable2') }} <small><i class="fas fa-external-link"/></small>
                    </a>
                  </div>
                </h5>
              </div>
            </label>
          </div>
          -->
          <!-- SOFORT END -->

          <!-- ACCOUNT WALLET -->
          <div class="col-lg col-sm-12 c-payment-gw-btn" v-on:click="selectPaymentGateway('cftools-wallet')" v-if="paymentGateways.cftools.wallet" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
            <label class="card-radio-label mb-3">
              <input type="radio" name="pay-method" class="card-radio-input" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}">
              <div class="card-radio">
                <i class="fad fa-wallet font-size-24 text-primary mt-2 me-2"></i>
                <h5 class="d-inline-block p-0 align-middle ml-2">
                  <span class="font-size-15">Crypto Payment</span>
                  <div class="small text-muted">
                    via CFTools Wallet
                  </div>
                </h5>
              </div>
            </label>
          </div>
          <!-- ACCOUNT WALLET -->

        </div>

        <template v-if="!paymentGateways.paypal.express && !paymentGateways.stripe.card && !paymentGateways.stripe.sepa">
          <div class="row mt-2 mb-2">
            <div class="col">
              <div class="alert alert-info text-center font-size-18">
                {{ $t('billing.payment.add.gateway.unavailable') }}
              </div>
            </div>
          </div>
        </template>

        <template v-if="paymentGateway === 'paypal'">
          <h3 class="text-center mt-2 mb-4">
            {{ $t('billing.payment.add.gateway.paypal.redirect') }}
            <small>
              <i class="fad fa-external-link-alt"/>
            </small>
          </h3>
        </template>

        <!-- Credit Card Payment -->
        <div class="c-stripe" ref="stripeCardPayment">
          <template v-if="paymentGateways.stripe && paymentGateways.stripe.environment === 'sandbox'">
            <h5 class="text-uppercase text-danger text-center">STRIPE SANDBOX ENVIRONMENT</h5>
          </template>
          <template v-else>
            <h6 class="text-center">
              <i class="fas fa-lock-alt text-primary"/>
              <span class="ml-1">
                {{ $t('billing.payment.add.disclaimer') }}
              </span>
            </h6>
          </template>

          <div class="row mt-1">
            <div class="col">
              <span>{{ $t('billing.payment.info.cc') }}</span>
              <div ref="stripeCard" id="stripeCard" class="form-control"></div>
              <span id="card-errors" class="h5 text-danger"></span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.first_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.firstName" :class="{'is-invalid': paymentInfoError}">
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.last_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.lastName" :class="{'is-invalid': paymentInfoError}">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.country') }}</span>
              <country-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.country_prompt')"
                  v-model="paymentInfo.country"
                  :country="paymentInfo.country"
                  :topCountry="paymentInfo.country"
                  :usei18n=false
                  :removePlaceholder=true
              />
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.region') }}</span>
              <region-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.region_prompt')"
                  v-model="paymentInfo.region"
                  :country="paymentInfo.country"
                  :region="paymentInfo.region"
                  :usei18n=false
              />
            </div>
          </div>
	        <div class="row mt-2">
		        <div class="col text-center font-size-24 text-white">
			        <i class="fab fa-cc-visa m-1"/>
			        <i class="fab fa-cc-mastercard m-1"/>
			        <i class="fab fa-cc-amex m-1"/>
			        <i class="fab fa-cc-diners-club m-1"/>
			        <i class="fab fa-cc-discover m-1"/>
			        <i class="fab fa-cc-jcb m-1"/>
		        </div>
	        </div>
        </div>
        <!-- --------- -->

        <!-- SEPA Payment -->
        <div class="c-stripe" ref="stripeSepaPayment">
          <template v-if="paymentGateways.stripe && paymentGateways.stripe.environment === 'sandbox'">
            <h5 class="text-uppercase text-danger text-center">STRIPE SANDBOX ENVIRONMENT</h5>
          </template>
          <template v-else>
            <h6 class="text-center">
              <i class="fas fa-lock-alt text-primary"/>
              <span class="ml-1">
                {{ $t('billing.payment.add.disclaimer') }}
              </span>
            </h6>
          </template>

          <div class="row mt-1">
            <div class="col">
              <span>{{ $t('billing.payment.info.iban') }}</span>
              <div ref="stripeIban" id="stripeIban" class="form-control" :class="{'is-invalid': paymentInfoError}"></div>
              <span id="iban-errors" class="h5 text-danger"></span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.first_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.firstName" :class="{'is-invalid': paymentInfoError}">
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.last_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.lastName" :class="{'is-invalid': paymentInfoError}">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.country') }}</span>
              <country-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.country_prompt')"
                  v-model="paymentInfo.country"
                  :country="paymentInfo.country"
                  :topCountry="paymentInfo.country"
                  :usei18n=false
                  :removePlaceholder=true
              />
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.region') }}</span>
              <region-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.region_prompt')"
                  v-model="paymentInfo.region"
                  :country="paymentInfo.country"
                  :region="paymentInfo.region"
                  :usei18n=false
              />
            </div>
          </div>
          <div class="row mt-2 justify-content-center">
            <div class="col">
              <div class="alert alert-info text-center small">
                {{ $t('billing.payment.add.sepa_disclaimer')}}
              </div>
            </div>
          </div>
        </div>
        <!-- --------- -->

        <!-- SOFORT Payment -->
        <div class="c-stripe" ref="stripeSofortPayment">
          <template v-if="paymentGateways.stripe && paymentGateways.stripe.environment === 'sandbox'">
            <h5 class="text-uppercase text-danger text-center">STRIPE SANDBOX ENVIRONMENT</h5>
          </template>
          <template v-else>
            <h6 class="text-center">
              <i class="fas fa-lock-alt text-primary"/>
              <span class="ml-1">
                {{ $t('billing.payment.add.disclaimer') }}
              </span>
            </h6>
          </template>

          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.first_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.firstName" :class="{'is-invalid': paymentInfoError}">
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.last_name') }}</span>
              <input class="form-control" type="text" v-model="paymentInfo.lastName" :class="{'is-invalid': paymentInfoError}">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.country') }}</span>
              <country-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.country_prompt')"
                  v-model="paymentInfo.country"
                  :country="paymentInfo.country"
                  :topCountry="paymentInfo.country"
                  :usei18n=false
                  :removePlaceholder=true
              />
            </div>
            <div class="col-lg-6 col-sm-12">
              <span>{{ $t('billing.payment.info.region') }}</span>
              <region-select
                  className="custom-select"
                  :class="{'is-invalid': paymentInfoError}"
                  :placeholder="$t('billing.payment.info.region_prompt')"
                  v-model="paymentInfo.region"
                  :country="paymentInfo.country"
                  :region="paymentInfo.region"
                  :usei18n=false
              />
            </div>
          </div>
          <div class="row mt-2 justify-content-center">
            <div class="col">
              <div class="alert alert-info text-center small">
                {{ $t('billing.payment.add.sepa_disclaimer')}}
              </div>
            </div>
          </div>
        </div>
        <!-- --------- -->

        <div class="row mt-3 justify-content-center" v-if="paymentGateway && ['cftools-wallet'].includes(paymentGateway)">
          <div class="col">
            <h6 class="text-center p-0 text-warning">
              <div class="col-lg-12 col-sm-12 mb-1">
                <b-form-checkbox
                    v-model="walletInfoAcknowledged"
                    class="custom-checkbox mb-3"
                >
                  <span class="text-white">
                    By creating a wallet, I understand accept the CFTools Wallet agreement of the CFTools <a href="https://cftools.cloud/legal/terms-of-use" target="_blank">Terms of Use</a>
                  </span>
                </b-form-checkbox>
              </div>

              Crypto currency payments are currently in beta and might require manual approval
            </h6>
          </div>
        </div>

        <div class="row justify-content-center mt-2" v-if="paymentGateway !== null">
          <div class="col-lg-4 col-sm-12">
            <button class="btn btn-outline-primary btn-block" :disabled="paymentGatewayProcessing" :class="{'disabled': paymentGatewayProcessing}" v-on:click="confirmPaymentGateway()">
              <half-circle-spinner
                  v-if="paymentGatewayProcessing"
                  :animation-duration="900"
                  :size="18"
                  class="align-middle d-inline-block"
              />
              {{ $t('billing.payment.add.confirm') }}
            </button>
          </div>
        </div>

        <div class="row mt-3 justify-content-center" v-if="paymentGateway && ['stripe-cc', 'stripe-sepa'].includes(paymentGateway)">
          <div class="col">
            <a href="https://stripe.com/" target="_blank">
              <h6 class="text-center p-0">
                {{ $t('billing.payment.add.stripe_disclaimer')}}
                <i class="fal fa-external-link"/>
              </h6>
            </a>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- !Modal Payment Method -->
  </div>
</template>
